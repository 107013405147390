import Map, {MarkerType} from "./Map";
import Cutout, {CutoutReactiveProps} from "./Cutout";
import Container from "./Container";
import RouteCollection from "./RouteCollection";
import ActionHistory from "../ActionHistory/ActionHistory";
import AddCutoutAction from "../ActionHistory/AddCutoutAction";
import DeleteCutoutAction from "../ActionHistory/DeleteCutoutAction";
import CutoutTemplate from "./CutoutTemplate";
import Serializer, {LinkData} from "./Serializer";
import Bookmarks from "./Bookmarks";
import WGS84, {WGS84System} from "../Coordinates/WGS84";
import {
    copyInput, eachPromise, enumKeys, enumValues,
    generateGpx,
    generateKml, isMobile, noModifierKeys,
    presentDownload,
    selectHtmlElementText, shiftKeyOnly, unreactive
} from "../Util/functions";
import {bsModal, createVue} from "../Util/vueFunctions";
import {JsPdfGenerator} from "./Printer";
import Route, {RouteReactiveProps} from "./Route";
import RouteDeleteAction from "../ActionHistory/RouteDeleteAction";
import LocationCollection from "./LocationCollection";
import Location, {LocationReactiveProps} from "./Location";
import LocationDeleteAction from "../ActionHistory/LocationDeleteAction";
import CoordinateConverter from "../Util/CoordinateConverter";
import Coordinate from "../Coordinates/Coordinate";
import ChangeCutoutNameAction from "../ActionHistory/ChangeCutoutNameAction";
import RouteChangeNameAction from "../ActionHistory/RouteChangeNameAction";
import LocationChangeNameAction from "../ActionHistory/LocationChangeNameAction";
import predictName from "../Util/NamePredictor";
import debounce from 'lodash.debounce';
import $ from "jquery";
import {App} from "@vue/runtime-core";
import {computed, reactive} from "vue";
import {Tab, Dropdown} from "bootstrap";
import RouteInitIntermediatesAction from "../ActionHistory/RouteInitIntermediatesAction";
import Toasts from "../Util/Toasts";
import QRCode from 'qrcode'
import Sortable from 'sortablejs';
import CutoutSortAction from "../ActionHistory/CutoutSortAction";
import RouteSortAction from "../ActionHistory/RouteSortAction";
import LocationSortAction from "../ActionHistory/LocationSortAction";
import UserError from "../Util/UserError";

type ObjectType = 'cutout'|'route'|'location';
type ObjectReactiveProps = CutoutReactiveProps|RouteReactiveProps|LocationReactiveProps;

export default class UserInterface {

    private map: Map;
    private cutouts: Cutout<any, any, any>[];
    private bookmarks: Bookmarks;
    private sortables: Record<ObjectType, Sortable> = <Record<ObjectType, Sortable>>{};

    private generalToolsDropdown: App;
    private objectList: App;
    private cutoutTemplatesWrapper: App;
    private cutoutTemplates;
    private generalSettingsModalWrapper: App;
    private bookmarksWrapper: App;
    private searchBarWrapper: App;
    private coordinatePanelWrapper: App;
    private coordinatePanel;
    private cutoutDropdownMenu: App;
    private generalDropdownMenu: App;
    private actionHistoryButtons: App;
    private shareUrlModal: App;
    private lastAddedCutoutTemplateId: number = null;
    private routeCollection: RouteCollection;
    private locationCollection: LocationCollection;

    private reactiveData = null;

    private cutoutCounter = 0;

    readonly colors: string[];

    readonly actionHistory: ActionHistory;
    private listeners: Record<string, (() => void)[]> = {};
    readonly toasts: Toasts;

    static readonly LOCALSTORAGE_KEY_STATISTICS_PARTICIPATION = 'statistics_participation';
    static readonly LOCALSTORAGE_FIRST_REQUEST_INFO_MODAL = 'first_request_info_modal';
    static readonly LOCALSTORAGE_LAST_VERSION_CHECK = 'last_version_check';

    constructor() {

        this.colors = ['blue', 'orange', 'green', 'fuchsia', 'lime', '#f33', '#ee0', 'aqua', 'black', 'maroon', 'navy', 'purple', 'teal', 'olive'];

        this.actionHistory = new ActionHistory();
        this.bookmarks = new Bookmarks(this);
        this.toasts = new Toasts();

        $(() => {
            this.onLoad();
        });

    }

    getMap(): Map {
        return this.map;
    }

    onLoad() {
        const onResize = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            if (this.map) {
                this.map.getOpenlayersMap().updateSize();
            }
        };
        window.addEventListener('resize', debounce(onResize, 200));
        onResize();

        window.addEventListener('beforeunload', (event) => {
            if (this.actionHistory.hasUnsaved() && !this.actionHistory.isTrivialWorkspace()) {
                event.preventDefault();
                return (event.returnValue = "");
            }
        });

        // In most cases we want to continue immediately
        let waitingPromise = Promise.resolve();
        let infoModalOpened = false;

        if(!window.localStorage.getItem(UserInterface.LOCALSTORAGE_FIRST_REQUEST_INFO_MODAL)) {
            // But when viewing the page for the first time, we want to ensure google indexes the page
            // correctly, hence we open a modal that is also informative to the user
            // This is related to the 'init-loading' and 'init-first-time' code on index.html
            bsModal('#infoModal').show();
            infoModalOpened = true;
            window.localStorage.setItem(UserInterface.LOCALSTORAGE_FIRST_REQUEST_INFO_MODAL, '1');
            waitingPromise = new Promise<void>((resolve) => {
                let done = false;
                const $body = $('body');
                const callback = () => {
                    if(done) {
                        return;
                    }
                    done = true;
                    $body.off('scroll click mousemove dragstart touchstart', callback);
                    resolve();
                };
                $body.on('scroll click mousemove dragstart touchstart', callback);
            });
        }

        if (window.location.hash === '#info' || window.location.hash.substr(0, 6) === '#info-') {
            if (!infoModalOpened) {
                bsModal('#infoModal').show();
            }

            // Scroll to anchor:
            window.location.hash = window.location.hash;
        }

        waitingPromise.then(() => {
            $('body').removeClass('init-loading init-first-time');

            this.onLoadInit();
        });
    }

    private onLoadInit() {
        const self = this;
        this.map = new Map(this, 'map-canvas');

        this.cutouts = [];
        this.routeCollection = new RouteCollection(this);
        this.locationCollection = new LocationCollection(this);

        const infoModalEl = document.querySelector('#infoModal');
        infoModalEl.addEventListener('show.bs.modal', function() {
            window.location.hash = '#info';
        });
        infoModalEl.addEventListener('hide.bs.modal', function() {
            window.location.hash = '';
        });

        $('#printAllButton').on('click', () => {
            if(confirm('Weet je zeker dat je een PDF van alle zichtbare kaartuitsnedes wilt downloaden?')) {
                this.printAllVisible();
            }
        });

        $('#downloadRoutesLocationsModal .download-button').on('click', function() {
            const $button = $(this);
            const format = $button.attr('data-download-format');
            const objects = $button.closest('#downloadRoutesLocationsModal').data('download_objects');

            if (!objects) {
                return;
            }

            const name = (objects.length === 1) ? objects[0].getName() : 'export';

            if (format === 'gpx') {
                presentDownload(name + '.gpx', generateGpx(objects));
            } else if (format === 'kml') {
                presentDownload(name + '.kml', generateKml(objects));
            }
        });

        const downloadRoutesLocationsModalEl = document.querySelector('#downloadRoutesLocationsModal');
        downloadRoutesLocationsModalEl.addEventListener('hidden.bs.modal', function (e) {
            $(downloadRoutesLocationsModalEl).data('download_objects', null);
        });

        this.reactiveData = reactive({
            initialized: false,
            cutoutsProps: [],
            objectListRecomputeCounter: 0,
            openObjectTypeTab: <ObjectType|'route-intermediates'>'cutout',
            cutoutDropdownMenuCutout: null,
            cutoutTemplatesNewCutoutTemplate: null,
            generalDropdownMenuOlCoordinate: null,
            userInterfaceOptions: {
                locked: false,
            },
            shareUrlModalData: {
                linkData: null,
                shortLinkUrl: null,
                shortLinkQrCode: null,
                shortLinkLoading: false,
            },
            sorting: {
                cutout: false,
                route: false,
                location: false,
            },
        });

        this.generalToolsDropdown = createVue('#generalToolsDropdown', {
            data: () => {
                return {
                    userInterfaceOptions: this.reactiveData.userInterfaceOptions,
                }
            },
            methods: {
                lock: (value) => {
                    if (value && !this.routeCollection.getSketchRoute().hasFocus()) {
                        this.routeCollection.unfocus();
                    }

                    for (const cutout of this.cutouts) {
                        $('#cutout_settings_modal_' + cutout.id).addClass('d-none');
                    }

                    self.setSorting('cutout', false);
                    self.setSorting('route', false);
                    self.setSorting('location', false);

                    this.reactiveData.userInterfaceOptions.locked = value;
                },
                share: () => {
                    this.displayShareModal((new Serializer()).createWorkspaceLink(this));
                },
                reloadApplication: () => {
                    if(confirm('Onopgeslagen werk gaat verloren. Doorgaan?')) {
                        this.reloadApplication();
                    }
                },
                clearCache: () => {
                    if(confirm('De buffer bevat gedownloade kaartstukken. Wil je deze legen?')) {
                        this.showLoadingIndicator();
                        Container.clearCaches().finally(() => {
                            this.hideLoadingIndicator();
                        });
                    }
                },
                resetStorage: () => {
                    if(confirm('Wil je alle opgeslagen werkruimtes, sjablonen en andere voorkeuren verwijderen?')) {
                        this.showLoadingIndicator();
                        Container.resetStorage();
                        this.trigger('storage-reset');
                        this.hideLoadingIndicator();
                    }
                },
                resetApplication: () => {
                    if(confirm('Wil je alle opgeslagen voorkeuren en buffers volledig verwijderen? Dit kan in sommige browsers even duren.')) {
                        this.showLoadingIndicator();
                        Container.resetApplication().finally(() => {
                            this.hideLoadingIndicator();
                        });
                    }
                },
            },
        });

        this.objectList = createVue('#objectList', {
            data: () => {
                return {
                    userInterfaceRetriever: () => this,
                    locked: computed(() => this.isLocked()),
                    cutoutsProps: this.reactiveData.cutoutsProps,
                    routesProps: this.routeCollection.getReactiveRoutesProps(),
                    locationsProps: this.locationCollection.getReactiveLocationsProps(),
                    recomputeCounter: computed(() => this.reactiveData.objectListRecomputeCounter),
                    focusedRouteRP: computed(() => this.routeCollection.reactiveProps.focusedRouteRP),
                    openObjectTypeTab: computed(() => this.reactiveData.openObjectTypeTab),
                    sorting: computed(() => this.reactiveData.sorting),
                    isMobile: isMobile(),
                }
            },
            computed: {
                prioritizeMoveCutoutHere: function () {
                    return this.cutoutsProps.length <= 1 && this.routesProps.length === 0 && this.locationsProps.length === 0;
                },
                locationFormatter: function() {
                    this.recomputeCounter;

                    const coordinateSystem = Container.getPreferredCoordinateSystem();
                    const format = (Container.getPreferredCoordinateFormats()[coordinateSystem.code]) || null;

                    return (location: Location) => {
                        return location.getFormattedCoordinate(coordinateSystem, format);
                    };
                },
                hasRouTech: function () {
                    for (const routeProps of self.routeCollection.getReactiveRoutesProps()) {
                        if (routeProps.hasInitializedIntermediates) {
                            return true;
                        }
                    }

                    return false;
                },
                sortingCurrent: function(): boolean {
                    return this.sorting[this.openObjectTypeTab];
                }
            },
            methods: {
                isInitialized: () => {
                    return this.reactiveData.initialized;
                },
                findCutout: (cutoutRP: CutoutReactiveProps) => {
                    return this.findCutout(cutoutRP);
                },
                findRoute: (routeRP: RouteReactiveProps) => {
                    return this.findRoute(routeRP);
                },
                findLocation: (locationRP: LocationReactiveProps) => {
                    return this.findLocation(locationRP);
                },
                toggleHidden: (cutoutRP: CutoutReactiveProps) => {
                    this.findCutout(cutoutRP).toggleVisibleOnMap(this.map);
                },
                print: (cutoutRP: CutoutReactiveProps) => {
                    this.print(this.findCutout(cutoutRP));
                },
                deleteCutout: (cutoutRP: CutoutReactiveProps) => {
                    this.deleteCutout(this.findCutout(cutoutRP));
                },
                moveMapToCutout: (cutoutRP: CutoutReactiveProps) => {
                    this.moveMapToCutout(this.findCutout(cutoutRP));
                },
                moveCutoutToCenter: (cutoutRP: CutoutReactiveProps) => {
                    this.moveCutoutToCenter(this.findCutout(cutoutRP));
                },
                duplicateCutout: (cutoutRP: CutoutReactiveProps) => {
                    this.duplicateCutout(this.findCutout(cutoutRP));
                },
                makeCutoutTemplate: (cutoutRP: CutoutReactiveProps) => {
                    this.makeCutoutTemplate(this.findCutout(cutoutRP));
                },
                shareCutout: (cutoutRP: CutoutReactiveProps) => {
                    this.displayShareModal((new Serializer()).createCutoutLink(this.findCutout(cutoutRP)));
                },
                downloadLegend: (cutoutRP: CutoutReactiveProps) => {
                    this.findCutout(cutoutRP).getProjection().getMapImageProvider().downloadLegend();
                },
                mouseover: (cutoutRP: CutoutReactiveProps) => {
                    this.findCutout(cutoutRP).mouseover();
                },
                mouseout: (cutoutRP: CutoutReactiveProps) => {
                    this.findCutout(cutoutRP)?.mouseout();
                },
                mouseoverRoute: (routeRP: RouteReactiveProps) => {
                    this.findRoute(routeRP).mouseover();
                },
                mouseoutRoute: (routeRP: RouteReactiveProps) => {
                    this.findRoute(routeRP)?.mouseout();
                },
                toggleRouteVisibility: (routeRP: RouteReactiveProps) => {
                    this.findRoute(routeRP).toggleVisibility();
                },
                toggleRouteFocus: (routeRP: RouteReactiveProps) => {
                    if (routeRP.hasFocus) {
                        this.routeCollection.unfocus();
                    } else {
                        this.routeCollection.focusRoute(this.findRoute(routeRP));
                    }
                },
                moveMapToRoute: (routeRP: RouteReactiveProps) => {
                    this.map.fitTo([], [this.findRoute(routeRP)], []);
                },
                reverseRoute: (routeRP: RouteReactiveProps) => {
                    this.findRoute(routeRP).reverse();
                },
                duplicateRoute: (routeRP: RouteReactiveProps) => {
                    this.routeCollection.duplicateRoute(this.findRoute(routeRP));
                },
                initRouteIntermediates: (routeRP: RouteReactiveProps) => {
                    const route = this.findRoute(routeRP);

                    this.actionHistory.addAction(new RouteInitIntermediatesAction(route));

                    this.showObjectListPanel('route-intermediates');
                    this.routeCollection.setRouTechSelectedRoute(route.id);
                    if (this.routeCollection.hasFocusedRoute() && !route.hasFocus()) {
                        this.routeCollection.unfocus();
                    }
                },
                downloadRoute: (routeRP: RouteReactiveProps) => {
                    $('#downloadRoutesLocationsModal').data('download_objects', [this.findRoute(routeRP)]);
                    bsModal('#downloadRoutesLocationsModal').show();
                },
                shareRoute: (routeRP: RouteReactiveProps) => {
                    this.displayShareModal((new Serializer()).createRouteLink(this.findRoute(routeRP)));
                },
                deleteRoute: (routeRP: RouteReactiveProps) => {
                    const route = this.findRoute(routeRP);
                    const index = this.routeCollection.getRoutes().indexOf(route);
                    if(index > -1) {
                        this.actionHistory.addAction(new RouteDeleteAction(route));
                    }
                },
                mouseoverLocation: (locationRP: LocationReactiveProps) => {
                    this.findLocation(locationRP).mouseover();
                },
                mouseoutLocation: (locationRP: LocationReactiveProps) => {
                    this.findLocation(locationRP)?.mouseout();
                },
                toggleLocationVisibility: (locationRP: LocationReactiveProps) => {
                    this.findLocation(locationRP).toggleVisibility();
                },
                moveMapToLocation: (locationRP: LocationReactiveProps) => {
                    this.map.fitTo([], [], [this.findLocation(locationRP)]);
                },
                duplicateLocation: (locationRP: LocationReactiveProps) => {
                    this.locationCollection.duplicateLocation(this.findLocation(locationRP));
                },
                downloadLocation: (locationRP: LocationReactiveProps) => {
                    $('#downloadRoutesLocationsModal').data('download_objects', [this.findLocation(locationRP)]);
                    bsModal('#downloadRoutesLocationsModal').show();
                },
                shareLocation: (locationRP: LocationReactiveProps) => {
                    this.displayShareModal((new Serializer()).createLocationLink(this.findLocation(locationRP)));
                },
                deleteLocation: (locationRP: LocationReactiveProps) => {
                    const location = this.findLocation(locationRP);
                    const index = this.locationCollection.getLocations().indexOf(location);
                    if(index > -1) {
                        this.actionHistory.addAction(new LocationDeleteAction(location));
                    }
                },
                toggleCoordinatePanelForLocation: (locationRP: LocationReactiveProps) => {
                    this.toggleCoordinatePanelForLocation(this.findLocation(locationRP));
                },
                selectLocationCoordinate: (evt) => {
                    selectHtmlElementText(evt.target);
                },
                editObjectName: function (evt, reactiveProps: ObjectReactiveProps, doubleClick: boolean) {
                    if (isMobile() !== doubleClick || self.isLocked() || this.sorting[reactiveProps.type]) {
                        return;
                    }

                    const $div = $(evt.target);
                    const $input = $div.siblings('.editableObjectNameWrapper').children('.editableObjectName');
                    $input.val(reactiveProps.name);
                    reactiveProps.quickEditingName = true;
                    setTimeout(() => $input.focus());
                },
                saveObjectName: (evt, reactiveProps: ObjectReactiveProps) => {
                    const $input = $(evt.target);
                    const newName = $input.val();
                    const object = this.findObject(reactiveProps);
                    if(newName !== object.name) {
                        if (object instanceof Cutout) {
                            this.actionHistory.addAction(new ChangeCutoutNameAction(object, newName));
                        } else if (object instanceof Route) {
                            this.actionHistory.addAction(new RouteChangeNameAction(object, newName));
                        } else if (object instanceof Location) {
                            this.actionHistory.addAction(new LocationChangeNameAction(object, newName));
                        }
                    }

                    $input.blur();

                    reactiveProps.quickEditingName = false;
                },
                inputObjectName: function (evt, reactiveProps: ObjectReactiveProps) {
                    if (
                        evt.which === 27 || evt.code === 'Escape'
                        || evt.which === 13 || evt.code === 'Enter'
                    ) {
                        this.saveObjectName(evt, reactiveProps);

                        evt.preventDefault();
                    }
                },
                setSorting(sorting: boolean): void {
                    self.setSorting(this.openObjectTypeTab, sorting);
                },
            }
        });

        for (const type of ['cutout', 'route', 'location']) {
            this.sortables[type] = Sortable.create(document.getElementById(type + '-panel'), {
                disabled: true, // Disables the sortable if set to true.

                handle: isMobile() ? '.sort-handle' : undefined,  // Drag handle selector within list items

                onUpdate: (evt) => {
                    const id = parseInt(evt.item.getAttribute('data-id'));

                    if (type === 'cutout') {
                        this.actionHistory.addAction(new CutoutSortAction(
                            this.cutouts.find(cutout => cutout.id === id),
                            this,
                            evt.newDraggableIndex,
                        ));
                    } else if (type === 'route') {
                        this.actionHistory.addAction(new RouteSortAction(
                            this.routeCollection.find(id),
                            evt.newDraggableIndex,
                        ));
                    } else if (type === 'location') {
                        this.actionHistory.addAction(new LocationSortAction(
                            this.locationCollection.find(id),
                            evt.newDraggableIndex,
                        ));
                    }
                },
            });
        }

        $('#objectListMinimizer').on('click', () => {
            $('#objectListPane').addClass('minimized');
        });
        $('#objectListMaximizer').on('click', () => {
            $('#objectListPane').removeClass('minimized');
        });

        $('#participate_statistics')
            .prop('checked', this.getStatisticsParticipation() === true)
            .on('change', () => {
                this.setStatisticsParticipation(
                    $('#participate_statistics').prop('checked')
                );
            });

        // incoming+marcovo-plattekaart-23855723-issue-@incoming.gitlab.com
        const CONTACT_MAILTO = 'mailto:incoming%2Bmarcovo-plattekaart-23855723-issue-%40incoming.gitlab.com';
        $('#contact_mail_link, #contact_mail_link2').on('click', () => {
            window.location.href = CONTACT_MAILTO;
        });

        $('#contact_mail_link_donate').on('click', () => {
            window.location.href = CONTACT_MAILTO + '?subject=Donatie&body=Ik zou graag een betaalverzoek ontvangen voor een donatie!';
        });

        $('#donate_link').on('click', () => {
            const buyMeACoffee = document.getElementById('donate_bymeacoffee');
            buyMeACoffee.setAttribute('src', buyMeACoffee.getAttribute('data-src'));

            bsModal('#donateLinkModal').show();
        });

        $('#download_locations_xlsx_button').on('click', () => {
            this.locationCollection.downloadAllXlsx();
        });

        $('#download_routes_locations_button').on('click', () => {
            const objects = [
                ...this.routeCollection.getRoutes(),
                ...this.locationCollection.getLocations(),
            ];

            $('#downloadRoutesLocationsModal').data('download_objects', objects);
            bsModal('#downloadRoutesLocationsModal').show();
        });

        this.cutoutTemplatesWrapper = createVue('#cutoutTemplatesWrapper', {
            data: function () {
                self.cutoutTemplates = this;
                return {
                    userInterfaceRetriever: () => self,
                    newCutoutTemplate: computed(() => unreactive(self.reactiveData.cutoutTemplatesNewCutoutTemplate)),
                    locked: computed(() => self.isLocked()),
                }
            },
            methods: {
                unlock: () => {
                    this.reactiveData.userInterfaceOptions.locked = false;
                },
            }
        });

        this.generalSettingsModalWrapper = createVue('#generalSettingsModalWrapper', {
            data: () => {
                return {
                    userInterfaceRetriever: () => this,
                }
            },
        });

        this.bookmarksWrapper = createVue('#bookmarksWrapper', {
            data: () => {
                return {
                    bookmarksRetriever: () => this.bookmarks,
                }
            },
        });

        this.searchBarWrapper = createVue('#searchBarWrapper', {
            data: () => {
                return {
                    userInterfaceRetriever: () => this,
                }
            },
        });

        this.coordinatePanelWrapper = createVue('#coordinatePanelWrapper', {
            data: function() {
                self.coordinatePanel = this;
                return {
                    userInterfaceRetriever: () => self,
                    olConvertibleCoordinateSystemRetriever: () => new WGS84System(),
                }
            },
        });

        this.cutoutDropdownMenu = createVue('#cutoutDropdownMenu', {
            data: () => {
                return {
                    locked: computed(() => this.isLocked()),
                    cutout: computed(() => unreactive(this.reactiveData.cutoutDropdownMenuCutout)),
                }
            },
            methods: {
                settings: (cutout: Cutout<any, any, any>) => {
                    // TODO: kind of ugly...
                    $('#cutout_' + cutout.id + '_settings').trigger('click');
                },
                print: (cutout: Cutout<any, any, any>) => {
                    this.print(cutout);
                },
                deleteCutout: (cutout: Cutout<any, any, any>) => {
                    this.deleteCutout(cutout);
                },
                duplicateCutout: (cutout: Cutout<any, any, any>) => {
                    this.duplicateCutout(cutout);
                },
                makeCutoutTemplate: (cutout: Cutout<any, any, any>) => {
                    this.makeCutoutTemplate(cutout);
                },
                shareCutout: (cutout: Cutout<any, any, any>) => {
                    this.displayShareModal((new Serializer()).createCutoutLink(cutout));
                },
                downloadLegend: (cutout: Cutout<any, any, any>) => {
                    cutout.getProjection().getMapImageProvider().downloadLegend();
                },
            }
        });

        this.generalDropdownMenu = createVue('#generalDropdownMenu', {
            data: () => {
                return {
                    olCoordinate: computed(() => unreactive(this.reactiveData.generalDropdownMenuOlCoordinate)),
                }
            },
            methods: {
                addLocation: function() {
                    if (this.olCoordinate) {
                        self.locationCollection.addLocation(this.olCoordinate);
                        self.reactiveData.generalDropdownMenuOlCoordinate = null;
                    }
                },
                addRoute: function() {
                    if (this.olCoordinate) {
                        self.routeCollection.addRoute().setCoordinates([this.olCoordinate]);
                        self.reactiveData.generalDropdownMenuOlCoordinate = null;
                    }
                },
                addRouteWithRoutech: function() {
                    if (this.olCoordinate) {
                        self.routeCollection.addRouteWithRoutech().setCoordinates([this.olCoordinate]);
                        self.reactiveData.generalDropdownMenuOlCoordinate = null;
                    }
                },
                addCutout: function() {
                    const coordinate = (<WGS84System>CoordinateConverter.getCoordinateSystem('EPSG:4326'))
                        .fromOpenLayersCoordinate(this.olCoordinate);
                    self.addCutout(false, coordinate);
                    self.reactiveData.generalDropdownMenuOlCoordinate = null;
                },
                openDropdown: function() {
                    setTimeout(() => {
                        self.cutoutTemplates.$refs.cutoutTemplates.openCutoutTemplatesDropdown();
                    });
                    self.reactiveData.generalDropdownMenuOlCoordinate = null;
                }
            }
        });

        this.actionHistoryButtons = createVue('#actionHistoryButtons', {
            data: () => {
                return {
                    actionHistoryRP: this.actionHistory.reactiveProps,
                    userInterfaceOptions: this.reactiveData.userInterfaceOptions,
                }
            },
            methods: {
                undo: () => {
                    this.actionHistory.undo();
                },
                redo: () => {
                    this.actionHistory.redo();
                },
            }
        });

        this.shareUrlModal = createVue('#shareUrlModal', {
            data: () => {
                return this.reactiveData.shareUrlModalData;
            },
            methods: {
                requestShortLink: function () {
                    if (!this.linkData || this.shortLinkLoading) {
                        return;
                    }

                    this.shortLinkLoading = true;

                    $.post('server/shortlinks.php?request=store', {
                        workspace: this.linkData.json,
                    })
                        .done(result => {
                            if (result === '0') {
                                alert('Er is iets misgegaan bij het genereren van de link');
                                return;
                            }

                            result = JSON.parse(result);
                            if (!result.short_link_key) {
                                alert('Er is iets misgegaan bij het genereren van de link');
                                return;
                            }

                            this.shortLinkUrl = Serializer.getBaseLink() + '?s=' + result.short_link_key;
                            QRCode.toDataURL(this.shortLinkUrl, {
                                margin: 1,
                                errorCorrectionLevel: 'M',
                            }).then(url => {
                                this.shortLinkQrCode = url;
                            })
                        })
                        .fail(() => {
                            alert('Er is iets misgegaan bij het genereren van de link');
                        })
                        .always(() => {
                            this.shortLinkLoading = false;
                        });
                },
                copyShareUrl() {
                    copyInput('#shareUrlModalInput');
                },
                copyShortUrl() {
                    copyInput('#shortUrlModalInput');
                },
            },
            watch: {
                linkData: function () {
                    this.shortLinkUrl = null;
                    this.shortLinkQrCode = null;
                    this.shortLinkLoading = false;
                }
            }
        });

        $('#objectListTabs a.nav-link').each(function () {
            this.addEventListener('shown.bs.tab', (e) => {
                self.reactiveData.openObjectTypeTab = $(e.target).attr('data-object-type');
            });
        });

        document.addEventListener('keydown', (evt: KeyboardEvent) => {
            if (evt.defaultPrevented) {
                return;
            }

            if (document.getElementById('infoModal').classList.contains('show')) {
                return;
            }

            if (evt.ctrlKey && (evt.key === 'f' || evt.code === 'KeyF')) {
                $('#searchButton').trigger('click');

                evt.preventDefault();
                return;
            }

            if (evt.key === '-' && noModifierKeys(evt) && !$(evt.target).is(':input')) {
                const mapView = this.map.getOpenlayersMap().getView();

                mapView.setZoom(mapView.getZoom() - 1);

                evt.preventDefault();
                return;
            }

            if (evt.key === '+' && (noModifierKeys(evt) || (evt.code === 'Equal' && shiftKeyOnly(evt))) && !$(evt.target).is(':input')) {
                const mapView = this.map.getOpenlayersMap().getView();

                mapView.setZoom(mapView.getZoom() + 1);

                evt.preventDefault();
                return;
            }

            if (this.isLocked()) {
                return;
            }

            if (evt.ctrlKey && (evt.key === 'z' || evt.which === 90 || evt.code === 'KeyZ')) {
                if (!$(evt.target).is(':input')) {
                    if (evt.shiftKey) {
                        this.actionHistory.redo();
                    } else {
                        this.actionHistory.undo();
                    }
                    evt.preventDefault();
                }
            }

            if (evt.ctrlKey && (evt.key === 'y' || evt.which === 89 || evt.code === 'KeyY')) {
                if (!$(evt.target).is(':input')) {
                    this.actionHistory.redo();
                    evt.preventDefault();
                }
            }
        });

        const urlParams = new URLSearchParams(window.location.search);
        const urlWorkspace = urlParams.get('workspace');
        const urlShortLinkKey = urlParams.get('s');
        let initPromise;
        if(urlWorkspace !== null) {
            initPromise = (new Serializer()).importFromLink(urlWorkspace, this);
        } else if (urlShortLinkKey !== null) {
            initPromise = (new Serializer()).importFromShortLinkKey(urlShortLinkKey, this);
        } else if (urlParams.get('c') !== null) {
            this.markUserInputCoordinate(urlParams.get('c'));
            initPromise = Promise.resolve();
            this.actionHistory.newWorkspace();
        } else {
            initPromise = this.addCutout(false)
                .catch((e) => {
                    alert('Kan standaardbron niet laden, voeg desgewenst zelf een (andere) kaart toe met de plusknop linksboven');
                })
                .then(() => {
                    if(this.actionHistory.getLength() === 1) {
                        this.actionHistory.newWorkspace();
                    }
                });
        }

        initPromise.finally(() => {
            this.reactiveData.initialized = true;
        });

        this.versionCheck();

        // Preloading
        for (let markerType of enumValues(MarkerType)) {
            this.map.getColoredMarkerImage(markerType).then(() => {
                eachPromise(this.colors, (color) => {
                    return this.map.getMapMarker(markerType, color).then(() => {
                        return this.map.getMapMarker(markerType, color, true).then();
                    });
                });
            });
        }
        this.map.getCoordinatePanelMarker();
    }

    on(key: string, callback: () => void) {
        if(this.listeners[key] === undefined) {
            this.listeners[key] = [];
        }
        this.listeners[key].push(callback);
    }

    trigger(key: string) {
        if(this.listeners[key] === undefined) {
            return;
        }

        for(const listener of this.listeners[key]) {
            listener();
        }
    }

    recomputeObjectList() {
        this.reactiveData.objectListRecomputeCounter++;
    }

    setReactives() {
        this.reactiveData.cutoutsProps.splice(0, this.reactiveData.cutoutsProps.length, ...this.cutouts.map(cutout => cutout.reactiveProps));
    }

    findCutout(reactiveProps: CutoutReactiveProps): Cutout<any, any, any> {
        return this.cutouts.find(cutout => cutout.id === reactiveProps.id);
    }

    findRoute(reactiveProps: RouteReactiveProps): Route {
        return this.routeCollection.find(reactiveProps.id);
    }

    findLocation(reactiveProps: LocationReactiveProps): Location {
        return this.locationCollection.find(reactiveProps.id);
    }

    findObject(reactiveProps: ObjectReactiveProps): Cutout<any, any, any>|Route|Location {
        if (reactiveProps.type === 'cutout') {
            return this.findCutout(reactiveProps);
        } else if (reactiveProps.type === 'route') {
            return this.findRoute(reactiveProps);
        } else if (reactiveProps.type === 'location') {
            return this.findLocation(reactiveProps);
        } else {
            throw new Error();
        }
    }

    getOpenObjectTypeTab(): ObjectType|'route-intermediates' {
        return this.reactiveData.openObjectTypeTab;
    }

    setSorting(objectType: ObjectType, sorting: boolean): void {
        this.reactiveData.sorting[objectType] = sorting;

        this.sortables[objectType].option('disabled', !sorting);

        document.getElementById(objectType + '-panel').classList.toggle('sorting', sorting);
    }

    versionCheck(): void {
        (new Promise((resolve, reject) => {
            const lastCheck = window.localStorage.getItem(UserInterface.LOCALSTORAGE_LAST_VERSION_CHECK);

            if (lastCheck) {
                const {date: lastCheckDate, version: lastCheckVersion} = JSON.parse(lastCheck);

                if ((+ new Date) - lastCheckDate < 24 * 60 * 60 * 1000) {
                    resolve(lastCheckVersion);
                    return;
                }
            }

            fetch('version.txt?nocache=' + (+ new Date())).then((response) => {
                response.text().then((latestVersion) => {
                    window.localStorage.setItem(UserInterface.LOCALSTORAGE_LAST_VERSION_CHECK, JSON.stringify({
                        date: + new Date,
                        version: latestVersion,
                    }));

                    resolve(latestVersion);
                });
            });
        })).then((latestVersion) => {
            const currentVersion = document.getElementById('release_version').getAttribute('data-version');

            if (currentVersion < latestVersion) {
                const toast = this.toasts.addToast(
                    'Nieuwe versie beschikbaar!',
                    `
                            Er is een nieuwe versie beschikbaar.
                            <a href="#" class="version-reload">Vernieuw de pagina</a> om deze te downloaden.
                            LET OP: onopgeslagen werk gaat verloren!
                            `
                );

                const toastEl = toast._element; // NOTE: accessing private element...
                const a = toastEl.querySelector('a.version-reload');
                a.addEventListener('click', (evt) => {
                    evt.preventDefault();
                    this.reloadApplication();
                });
            }
        });
    }

    reloadApplication(): void {
        window.localStorage.removeItem(UserInterface.LOCALSTORAGE_LAST_VERSION_CHECK);
        location.reload();
    }

    getStatisticsParticipation(): boolean|null {
        const choice = window.localStorage.getItem(UserInterface.LOCALSTORAGE_KEY_STATISTICS_PARTICIPATION);
        if(choice === '1') return true;
        if(choice === '0') return false;
        return null;
    }

    setStatisticsParticipation(choice: boolean) {
        window.localStorage.setItem(
            UserInterface.LOCALSTORAGE_KEY_STATISTICS_PARTICIPATION,
            choice ? '1' : '0'
        );

        $('#participate_statistics').prop('checked', choice);

        try {
            $.post('server/stats.php?request=participation', {
                choice: choice ? '1' : '0',
            });
        } catch(e) {
            console.log(e);
        }
    }

    checkStatisticsParticipation(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const choice = this.getStatisticsParticipation();
            if(choice === null) {
                const modalEl = document.querySelector('#statisticsParticipationModal');
                const modal = bsModal(modalEl);
                const listener = function (e) {
                    reject();
                };

                modalEl.addEventListener('hidden.bs.modal', listener);
                modal.show();

                $('#statisticsParticipationModalNo, #statisticsParticipationModalYes').off('click');

                $('#statisticsParticipationModalNo').on('click', () => {
                    this.setStatisticsParticipation(false);
                    modalEl.removeEventListener('hidden.bs.modal', listener);
                    modal.hide();
                    resolve(false);
                });

                $('#statisticsParticipationModalYes').on('click', () => {
                    this.setStatisticsParticipation(true);
                    modalEl.removeEventListener('hidden.bs.modal', listener);
                    modal.hide();
                    resolve(true);
                });
            } else {
                resolve(choice);
            }
        });
    }

    openCutoutDropdownMenu(cutout: Cutout<any, any, any>, evt) {
        this.reactiveData.cutoutDropdownMenuCutout = cutout;

        Dropdown.getOrCreateInstance('#cutoutDropdownMenuButton').show();
        setTimeout(() => {
            $('#cutoutDropdownMenuButton')
                .siblings('.dropdown-menu')
                .css({
                    top: evt.clientY + 'px',
                    left: evt.clientX + 'px',
                })
            ;
        });
    }

    openGeneralDropdownMenu(evt) {
        this.reactiveData.generalDropdownMenuOlCoordinate = this.map.getOpenlayersMap().getCoordinateFromPixel([
            evt.clientX,
            evt.clientY,
        ]);

        Dropdown.getOrCreateInstance('#generalDropdownMenuButton').show();
        setTimeout(() => {
            $('#generalDropdownMenuButton')
                .siblings('.dropdown-menu')
                .css({
                    top: evt.clientY + 'px',
                    left: evt.clientX + 'px',
                })
            ;
        });
    }

    private loadingIndicatorCounter: number = 0;
    showLoadingIndicator(delay = 0) {
        this.loadingIndicatorCounter++
        setTimeout(() => {
            if(this.loadingIndicatorCounter > 0) {
                $('#mainLoadingIndicator').show();
            }
        }, delay);
    }
    hideLoadingIndicator() {
        this.loadingIndicatorCounter--;
        $('#mainLoadingIndicator').hide();
    }

    cutoutLoading(cutout: Cutout<any, any, any>, progress: number|null) {
        if(progress === null) {
            $('#cutout_' + cutout.id + '_loading').addClass('lds-dual-ring-hidden');
            $('#cutout_' + cutout.id + '_loading_progress').css({width: 0});
        } else {
            $('#cutout_' + cutout.id + '_loading').removeClass('lds-dual-ring-hidden');
            $('#cutout_' + cutout.id + '_loading_progress').css({width: (progress*100) + '%'});
        }
    }

    newColor(): string {
        const colorCounts = {};
        for(const color of this.colors) {
            colorCounts[color] = 0;
        }

        this.getCutouts().forEach((cutout) => {
            if(colorCounts.hasOwnProperty(cutout.color)) {
                colorCounts[cutout.color]++;
            }
        });

        this.getRouteCollection().getRoutes().forEach((route) => {
            if(colorCounts.hasOwnProperty(route.getColor())) {
                colorCounts[route.getColor()]++;
            }
        });

        this.getLocationCollection().getLocations().forEach((location) => {
            if(colorCounts.hasOwnProperty(location.getColor())) {
                colorCounts[location.getColor()]++;
            }
        });

        let lowestCountColor = null;
        let lowestCount = null;
        for(const color in colorCounts) {
            if(lowestCount === null || colorCounts[color] < lowestCount) {
                lowestCount = colorCounts[color];
                lowestCountColor = color;
            }
        }

        return lowestCountColor;
    }

    markUserInputCoordinate(input: string): void {
        const parsedInput = this.parseUserInputCoordinate(input);
        if (parsedInput === null) {
            alert('De gegeven URL is misvormd, kan gevraagde locatie niet weergeven.');
            return;
        }

        const [coordinate, zoom, heading] = parsedInput;

        this.openCoordinatePanelForCoordinate(coordinate);
        this.getMap().fitToCoordinate(coordinate);
        if (zoom !== null) {
            this.getMap().getOpenlayersMap().getView().setZoom(zoom);
        }
        if (heading !== null) {
            this.getMap().getOpenlayersMap().getView().setRotation((360 - heading) / 180 * Math.PI);
        }
    }

    private parseUserInputCoordinate(input: string): [WGS84, number|null, number|null]|null {
        const parts = input.split(',');
        if (parts.length < 2) {
            return null;
        }

        // Lng-lat
        const floatRegex = /^([\-]?\d+(?:\.\d+)?)$/;
        if (!parts[0].match(floatRegex) || !parts[1].match(floatRegex)) {
            return null;
        }

        const coordinate = WGS84.fromStrings(parts[1], parts[0]);
        if (coordinate === null) {
            return null;
        }

        // Zoom, heading
        const options = {
            z: null,
            h: null,
        };
        for (let i = 2; i < parts.length; i++) {
            const part = parts[i];
            if (part.endsWith('h') || part.endsWith('z')) {
                const numberString = part.substring(0, part.length - 1);
                const key = part.substring(part.length - 1);

                if (!numberString.match(floatRegex)) {
                    continue;
                }
                const number = parseFloat(numberString);
                if (isNaN(number)) {
                    continue;
                }
                if (key === 'h' && (number < 0 || number > 360)) {
                    return null;
                }
                if (key === 'z' && (number < 0 || number > 30)) {
                    return null;
                }
                options[key] = number;
            }
        }

        return [coordinate, options.z, options.h];
    }

    toggleCoordinatePanelForLocation(location: Location) {
        this.coordinatePanel.$refs.coordinatePanel.toggleCoordinatePanelForLocation(location);
    }

    openCoordinatePanelForCoordinate(coordinate: Coordinate, name: string|null = null) {
        this.coordinatePanel.$refs.coordinatePanel.openCoordinatePanel(coordinate, name);
    }

    openGeneralSettings(options) {
        $('#generalSettingsButton').trigger('click', options);
    }

    displayShareModal(linkData: LinkData) {
        this.reactiveData.shareUrlModalData.linkData = linkData;

        bsModal('#shareUrlModal').show();
    }

    showObjectListPanel(panel: 'cutout'|'route'|'location'|'route-intermediates')
    {
        Tab.getOrCreateInstance(document.querySelector('#objectListTabs a.nav-link#' + panel + '-tab')).show();
    }

    addCutoutFromTemplate(
        cutoutTemplate: CutoutTemplate<any, any, any>,
        center: boolean = true,
        anchorCoordinate: Coordinate|null = null
    ): Promise<void> {
        return cutoutTemplate.makeCutout(this)
            .then((cutout) => {
                if(center) {
                    return cutout.moveToWindowCenter(false).then((success) => {
                        if(!success) {
                            alert('De toegevoegde kaart kon niet op een geldige plaats binnen het scherm worden geplaatst. De kaart is op een geldige positie geplaatst buiten het zichtbare scherm.');
                        }
                        return cutout;
                    });
                } else if(anchorCoordinate) {
                    cutout.setAnchorWorkspaceCoordinate(CoordinateConverter.convert(
                        anchorCoordinate,
                        cutout.workspaceCoordinateSystem
                    ));
                    return cutout;
                } else {
                    return Promise.resolve(cutout);
                }
            })
            .then((cutout) => {
                const number = ++this.cutoutCounter;
                cutout.reactiveProps.name = cutout.name = predictName(this.cutouts.map((cutout) => cutout.name)) || 'Kaart ' + number;
                cutout.reactiveProps.color = cutout.color = this.newColor();

                this.actionHistory.addAction(new AddCutoutAction(
                    cutout,
                    this,
                    this.cutouts.length
                ));

                this.lastAddedCutoutTemplateId = cutoutTemplate.id;
            });
    }

    addObject(): Promise<void> {
        const openObjectTypeTab = this.getOpenObjectTypeTab();
        if (openObjectTypeTab === 'route' || openObjectTypeTab === 'route-intermediates') {
            this.routeCollection.addRoute();
            return Promise.resolve();
        } else if (openObjectTypeTab === 'location') {
            this.locationCollection.addLocation();
            return Promise.resolve();
        } else {
            return this.addCutout();
        }
    }

    addCutout(center: boolean = true, anchorCoordinate: Coordinate|null = null): Promise<void> {
        const cutoutTemplates = Container.cutoutTemplateList();
        if(cutoutTemplates.length === 0) {
            throw new Error('No cutout templates');
        }

        let cutoutTemplate = null;
        if(this.lastAddedCutoutTemplateId !== null) {
            for(const item of cutoutTemplates) {
                if(item.id === this.lastAddedCutoutTemplateId) {
                    cutoutTemplate = item;
                    break;
                }
            }
        }

        if(cutoutTemplate === null) {
            cutoutTemplate = cutoutTemplates[0];
        }

        return this.addCutoutFromTemplate(cutoutTemplate, center, anchorCoordinate);
    }

    public attachCutout(cutout: Cutout<any, any, any>, position: number) {
        cutout.addToMap(this.map);
        this.cutouts.splice(position, 0, cutout);
        this.setReactives();
        this.showObjectListPanel('cutout');
    }

    public detachCutout(cutout: Cutout<any, any, any>) {
        const index = this.cutouts.indexOf(cutout);
        if(index === -1) {
            throw new Error('Invalid cutout');
        }

        cutout.removeFromMap(this.map);
        this.cutouts.splice(index, 1);
        this.setReactives();
        this.showObjectListPanel('cutout');
    }

    public moveCutoutInList(cutout: Cutout<any, any, any>, newIndex: number): void {
        const index = this.cutouts.indexOf(cutout);
        if (index === -1) {
            throw new Error('Invalid cutout');
        }

        this.cutouts.splice(index, 1);
        this.cutouts.splice(newIndex, 0, cutout);

        this.setReactives();
        this.showObjectListPanel('cutout');
    }

    getCutouts() {
        return this.cutouts;
    }

    getRouteCollection() {
        return this.routeCollection;
    }

    getLocationCollection() {
        return this.locationCollection;
    }

    isLocked() {
        return this.reactiveData.userInterfaceOptions.locked;
    }

    setFromUnserialize(cutouts: Cutout<any, any, any>[], routes: Route[], locations: Location[], userInterfaceOptions) {
        for (const key of Object.keys(userInterfaceOptions)) {
            if (typeof this.reactiveData.userInterfaceOptions[key] !== 'undefined') {
                this.reactiveData.userInterfaceOptions[key] = userInterfaceOptions[key];
            }
        }

        this.actionHistory.clear();

        for(const cutout of this.cutouts) {
            cutout.removeFromMap(this.map);
        }
        this.cutouts.splice(0);

        this.routeCollection.unfocus();
        for(const route of this.routeCollection.getRoutes().slice()) {
            this.routeCollection.detachRoute(route);
        }

        for(const location of this.locationCollection.getLocations().slice()) {
            this.locationCollection.detachLocation(location);
        }

        for(const cutout of cutouts) {
            const shouldBeVisible = cutout.visibleOnMap;
            this.attachCutout(cutout, this.cutouts.length);
            if(!shouldBeVisible) {
                cutout.toggleVisibleOnMap(this.map, false);
            }
        }

        for (const route of routes) {
            this.routeCollection.attachRoute(route, this.routeCollection.getRoutes().length);
        }

        for (const location of locations) {
            this.locationCollection.attachLocation(location, this.locationCollection.getLocations().length);
        }

        this.setReactives();

        this.map.fitTo(this.cutouts, this.routeCollection.getRoutes(), this.locationCollection.getLocations());
    }

    print(cutout: Cutout<any, any, any>): void {
        this.cutoutLoading(cutout, 0);

        const progressCallback = (evt) => {
            this.cutoutLoading(cutout, evt.done / evt.total);
        };

        cutout.printAndDownload(progressCallback).catch((e) => {
            console.log(e || 'Something went wrong while printing');
            alert(e instanceof UserError ? e.message : 'Something went wrong while printing');
        }).finally(() => {
            this.cutoutLoading(cutout, null);
        });
    }

    printAllVisible(): void {
        const cutouts: Cutout<any, any, any>[] = [];
        for(const cutout of this.cutouts) {
            if(cutout.visibleOnMap) {
                cutouts.push(cutout);
            }
        }

        if(cutouts.length === 0) {
            alert('Geen zichtbare kaartuitsnedes.');
            return;
        }

        this.showObjectListPanel('cutout');

        const notInBounds = [];
        eachPromise(cutouts, (cutout) => {
            return cutout.isInBoundingBox().then((isInBoundingBox) => {
                if(!isInBoundingBox) {
                    notInBounds.push(cutout.name);
                }
            });
        }).then(() => {
            if(notInBounds.length > 0) {
                if(!confirm('De kaartuitsnede(s) ' + notInBounds.join(', ') + ' bevinden zich buiten het definitiegebied van de kaartbron. Verplaats deze kaartuitsnedes of selecteer een andere kaartbron. Wil je doorgaan met downloaden?')) {
                    return;
                }
            }

            const jsPdfGenerator = new JsPdfGenerator();

            for(const cutout of cutouts) {
                this.cutoutLoading(cutout, 0);
            }

            eachPromise(cutouts, (cutout) => {
                const progressCallback = (evt) => {
                    this.cutoutLoading(cutout, evt.done / evt.total);
                };

                return cutout.printOnNewPage(jsPdfGenerator, progressCallback).then(() => {
                    this.cutoutLoading(cutout, null);
                });
            }).then(() => {
                jsPdfGenerator.getJsPdf().save('maps.pdf');
            }).catch((e) => {
                console.log(e);
                alert(e instanceof UserError ? e.message : 'Something went wrong while printing');
            }).finally(() => {
                for(const cutout of cutouts) {
                    this.cutoutLoading(cutout, null);
                }
            });
        }).catch((e) => {
            console.log(e);
            alert(e instanceof UserError ? e.message : 'Something went wrong while printing');
        });
    }

    deleteCutout(cutout: Cutout<any, any, any>): void {
        const index = this.cutouts.indexOf(cutout);
        if(index > -1) {
            this.actionHistory.addAction(new DeleteCutoutAction(cutout, this));
        }
    }

    moveMapToCutout(cutout: Cutout<any, any, any>): void {
        this.map.fitTo([cutout], [], []);
    }

    moveCutoutToCenter(cutout: Cutout<any, any, any>): void {
        cutout.moveToWindowCenter(true).then((success) => {
            if(!success) {
                alert('De kaart kon niet op een geldige plaats binnen het scherm worden geplaatst.');
            }
        });
    }

    duplicateCutout(sourceCutout: Cutout<any, any, any>): void {
        const newCutout = sourceCutout.clone();

        newCutout.reactiveProps.name = newCutout.name = sourceCutout.name + ' (kopie)';
        newCutout.reactiveProps.color = newCutout.color = this.newColor();

        this.actionHistory.addAction(new AddCutoutAction(
            newCutout,
            this,
            this.cutouts.length
        ));
    }

    makeCutoutTemplate(sourceCutout: Cutout<any, any, any>): void {
        this.reactiveData.cutoutTemplatesNewCutoutTemplate = sourceCutout.makeTemplate();
    }

    requestErrorReport(errorLog: string) {
        return new Promise<Record<string, string>>((resolve, reject) => {
            $('#errorModalErrorLog').val(errorLog);
            $('#errorModalDescription').val('');
            $('#errorModalIncludeHistory').prop('checked', true);
            $('#errorModalIncludeWorkspace').prop('checked', true);
            $('#errorModalContact').val('');

            const errorModalEl = document.querySelector('#errorModal');
            const errorModal = bsModal(errorModalEl);

            const onHidden = function (e) {
                errorModalEl.removeEventListener('hidden.bs.modal', onHidden);
                $('#errorModalSubmit').off('click');
            };
            errorModalEl.addEventListener('hidden.bs.modal', onHidden);
            errorModal.show();

            $('#errorModalSubmit').off('click').on('click', () => {
                errorModal.hide();

                let history = null, workspace = null;
                if ($('#errorModalIncludeHistory').prop('checked')) {
                    try {
                        history = this.actionHistory.serializeForDebug();
                    } catch (error) {
                        history = 'error: ' + JSON.stringify(error, Object.getOwnPropertyNames(error));
                    }
                }

                if ($('#errorModalIncludeWorkspace').prop('checked')) {
                    try {
                        workspace = (new Serializer()).serializeWorkspace(this);
                    } catch (error) {
                        workspace = 'error: ' + JSON.stringify(error, Object.getOwnPropertyNames(error));
                    }
                }

                resolve({
                    description: $('#errorModalDescription').val(),
                    history: history,
                    workspace: workspace,
                    contact: $('#errorModalContact').val(),
                });
            });
        });
    }

}
